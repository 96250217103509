Drupal.behaviors.dd_calculator_feature_strom = {
  attach: function() {
    return jQuery('.calculator--widget--slider--rail__strom').once('calculator-strom', function() {
      var $slider, defaultIndex, defaultValue, volume;
      volume = [1800, 2400, 3200, 4000, 4800];
      if (Drupal.settings.dd_calculator) {
        defaultValue = parseInt(Drupal.settings.dd_calculator.strom.consum);
      } else {
        defaultValue = 2400;
      }
      defaultIndex = jQuery.inArray(defaultValue, volume);
      $slider = jQuery(this).slider({
        range: 'max',
        min: 1,
        max: 5,
        value: 5,
        slide: function(event, ui) {
          jQuery(ui.handle).html("<span>" + ui.value + "</span>");
          $slider.parents('form').find('input.calculator--widget--consum').val(volume[ui.value - 1]);
          return true;
        }
      });
      $slider.data('uiSlider')._slide(false, 0, defaultIndex + 1);
    });
  }
};

Drupal.behaviors.dd_calculator_feature_gas = {
  attach: function() {
    return jQuery('.calculator--widget--ranges').once('calculator-gas', function() {
      var $lis, $self, $target, defaultIndex, defaultValue, key, volume;
      volume = {
        30: 5000,
        50: 7500,
        100: 16000,
        150: 22000,
        180: 28000
      };
      $self = jQuery(this);
      $target = $self.parents('form').find('input.calculator--widget--consum');
      $lis = $self.find('li');
      if (Drupal.settings.dd_calculator) {
        defaultValue = parseInt(Drupal.settings.dd_calculator.gas.consum);
      } else {
        defaultValue = 7500;
      }
      for (key in volume) {
        if (defaultValue === volume[key]) {
          defaultIndex = key;
        }
      }
      jQuery('li[data-value="' + defaultIndex + '"]').addClass('is-active');
      return $self.on('click', 'li', function() {
        var $li, _value;
        $lis.removeClass('is-active');
        $li = jQuery(this).addClass('is-active');
        _value = $li.attr('data-value');
        return $target.val(volume[_value]);
      });
    });
  }
};

Drupal.behaviors.dd_calculator_feature_wasser = {
  attach: function() {
    return jQuery('.calculator--widget--slider--rail__wasser').once('calculator-wasser', function() {
      var $slider, defaultIndex, defaultValue, volume;
      volume = [40, 80, 120, 160, 240];
      if (Drupal.settings.dd_calculator) {
        defaultValue = parseInt(Drupal.settings.dd_calculator.wasser.consum);
      } else {
        defaultValue = 80;
      }
      defaultIndex = jQuery.inArray(defaultValue, volume);
      $slider = jQuery(this).slider({
        range: 'max',
        min: 1,
        max: 5,
        value: 5,
        slide: function(event, ui) {
          jQuery(ui.handle).html("<span>" + ui.value + "</span>");
          $slider.parents('form').find('input.calculator--widget--consum').val(volume[ui.value - 1]);
          return true;
        }
      });
      $slider.data('uiSlider')._slide(false, 0, defaultIndex + 1);
    });
  }
};

Drupal.behaviors.dd_calculator_rate = {
  attach: function() {
    var $rates;
    $rates = jQuery('.node-dd-calculator-rate.js--dd-calculator-rate');
    $rates.each(function(index, element) {
      var button, close, rate, setActive;
      rate = jQuery(element);
      button = rate.find('.dd-calculator-rate--circle');
      close = rate.find('.dd-calculator-rate--header-close');
      setActive = (function(_this) {
        return function() {
          var header;
          header = jQuery(_this).find('.dd-calculator-rate--header');
          if (!jQuery(_this).hasClass('js--only-open')) {
            if (jQuery(header).hasClass('is-active')) {
              jQuery(header).removeClass('is-active');
            } else {
              jQuery(header).addClass('is-active');
            }
          }
          if (jQuery(_this).hasClass('is-open')) {
            jQuery(_this).removeClass('is-open');
          } else {
            jQuery(_this).addClass('is-open');
          }
          return event.preventDefault();
        };
      })(this);
      button.click(function(event) {
        return setActive();
      });
      return close.click(function(event) {
        return setActive();
      });
    });
  }
};

Drupal.behaviors.dd_calculator_widget_toggle = {
  attach: function() {
    return jQuery('.calculator--widget--wrapper').once('calculator-widgets', function() {
      var $active_widget, $rail, $trigger, $widgets, $wrapper;
      $wrapper = jQuery(this);
      $trigger = $wrapper.find('.calculator--widget--trigger');
      $rail = $wrapper.find('.calculator--widget--wrapper--inner');
      $widgets = $wrapper.find('.calculator--widget');
      $active_widget = $wrapper.find('.calculator--widget.is-active');
      return $trigger.click(function(event) {
        var $widget;
        event.preventDefault();
        $widget = jQuery(this).parents('.calculator--widget');
        $wrapper.removeClass('is-active-strom is-active-gas is-active-wasser');
        $wrapper.addClass('is-active-' + jQuery(this).data('calctype'));
        if ($widget.hasClass('is-active')) {
          return;
        }
        $widgets.removeClass('is-active');
        return $widget.addClass('is-active');
      });
    });
  }
};
