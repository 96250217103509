var FooterNavigation, Modernizr;

Modernizr = require('../modernizr');

FooterNavigation = (function() {
  function FooterNavigation() {
    this.header = jQuery('.header');
    this.footer_products = jQuery('.menu-mlid-2142, .menu-mlid-2827');
    this.footer_products_link = this.footer_products.find('> a');
    Breakpoints.on({
      name: "TO_MOBILE_NAVIGATION_BREAKPOINT",
      el: this.header[0],
      matched: (function(_this) {
        return function() {
          return _this.matched_mobile.apply(_this, arguments);
        };
      })(this)
    });
    Breakpoints.on({
      name: "FROM_MOBILE_NAVIGATION_BREAKPOINT",
      el: this.header[0],
      matched: (function(_this) {
        return function() {
          return _this.exit_mobile.apply(_this, arguments);
        };
      })(this)
    });
  }

  FooterNavigation.prototype.matched_mobile = function() {
    return this.footer_products_link.on('click.footer', (function(_this) {
      return function(event) {
        event.preventDefault();
        return _this.footer_products.toggleClass('active-trail');
      };
    })(this));
  };

  FooterNavigation.prototype.exit_mobile = function() {
    return this.footer_products_link.off('.footer');
  };

  return FooterNavigation;

})();

module.exports = FooterNavigation;
