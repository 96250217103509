var SocialSharePrivacy;

SocialSharePrivacy = (function() {
  function SocialSharePrivacy() {
    jQuery(".social_share_privacy--share_inner").once('social_share_privacy', function(i, element) {
      var _string, _this;
      _string = Drupal.t('Two steps for more data protection: To share or recommend this page on %network, please click here first to activate the button. The information will be transferred after the button is activated.');
      _this = jQuery(element);
      return _this.socialSharePrivacy({
        services: {
          facebook: {
            status: "on",
            perma_option: "on",
            txt_info: Drupal.formatString(_string, {
              '%network': 'Facebook'
            })
          },
          twitter: {
            status: "on",
            perma_option: "on",
            tweet_text: document.title + " | ",
            referrer_track: '?utm_medium=twitter&utm_source=2-click-twitter-button&utm_term=2ClickTweetButton&utm_campaign=',
            txt_info: Drupal.formatString(_string, {
              '%network': 'Twitter'
            })
          },
          gplus: {
            status: "on",
            perma_option: "on",
            referrer_track: '?utm_medium=googleplus&utm_source=2-click-googleplus-button&utm_term=2ClickGoogleplusButton&utm_campaign=',
            txt_info: Drupal.formatString(_string, {
              '%network': 'Google+'
            })
          },
          xing: {
            status: "on",
            perma_option: "on",
            referrer_track: '?utm_medium=xing&utm_source=2-click-xing-button&utm_term=2ClickXingButton&utm_campaign=',
            txt_info: Drupal.formatString(_string, {
              '%network': 'XING'
            })
          },
          linkedin: {
            status: "on",
            perma_option: "on",
            txt_info: Drupal.formatString(_string, {
              '%network': 'LinkedIn'
            })
          }
        },
        display_infobox: "off"
      });
    });
  }

  return SocialSharePrivacy;

})();

module.exports = SocialSharePrivacy;
