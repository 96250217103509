Drupal.behaviors.dd_order_city = {
  attach: function() {
    var $zips;
    $zips = jQuery('.form-item-order-zip input, .form-item-order-billing-zip input');
    $zips.each(function(index, element) {
      var setCity, zip;
      zip = jQuery(element);
      setCity = (function(_this) {
        return function() {
          var $value;
          zip = jQuery(_this);
          $value = zip.val();
          if ($value.length === 5) {
            return jQuery.ajax({
              type: 'POST',
              url: 'dd-address-city/' + $value,
              data: {},
              success: function(content) {
                var parent;
                parent = zip.parents('.form-type-textfield').next();
                parent.find('input').val(content);
              }
            });
          }
        };
      })(this);
      zip.keyup(function(event) {
        return setCity();
      });
      return zip.ready(function(event) {
        return setCity();
      });
    });
  }
};
