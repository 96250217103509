var DDSlider, DDSliderWatcher, Modernizr;

require("../../../../../../bower_components/bxslider-4/jquery.bxslider.js");

require("../../../../../../bower_components/jquery-backstretch/jquery.backstretch.js");

Modernizr = require('../modernizr');

DDSlider = (function() {
  var $itemNumber;

  function DDSlider(element) {
    this.slider = jQuery(element);
    this.pager = this.slider.find('.slider--pager');
    this.pagerItems = this.pager.find('li');
    this.controlLeft = this.slider.find('.bx-prev');
    this.controlRight = this.slider.find('.bx-next');
    this.images = this.slider.find('.slider--images img');
    this.sliderItems = this.slider.find('.slider--images li');
    Breakpoints.on({
      name: "MOBILE_BREAKPOINT",
      matched: (function(_this) {
        return function() {
          return _this.matched_mobile.apply(_this, arguments);
        };
      })(this),
      exit: (function(_this) {
        return function() {
          return _this.exit_mobile.apply(_this, arguments);
        };
      })(this)
    });
  }

  $itemNumber = "2";

  DDSlider.prototype.create_slider = function($itemNumber) {
    var $slide_width, _backstretch, _mode, _self, options;
    if (this.slider_is_created != null) {
      return;
    }
    this.slider_is_created = true;
    if (this.slider.find('.slider--images li').length < 2) {
      this.slider.addClass('slider__single');
      return;
    }
    _mode = 'horizontal';
    if (this.slider.hasClass('slider__vertical')) {
      _mode = 'fade';
    }
    _backstretch = function($item) {};
    _self = this;
    options = {
      mode: _mode,
      pager: true,
      controls: false,
      auto: true,
      autoStart: true,
      autoHover: true,
      speed: 1000,
      pause: 6000,
      useCSS: false,
      adaptiveHeight: true,
      prevText: this.controlLeft.html(),
      nextText: this.controlRight.html(),
      onSlideBefore: (function(_this) {
        return function($slideElement, oldIndex, newIndex) {
          _this.pagerItems.eq(oldIndex).removeClass('is-active');
          return _this.pagerItems.eq(newIndex).addClass('is-active');
        };
      })(this),
      onSliderLoad: function(currentIndex) {
        return _self.pagerItems.eq(currentIndex).addClass('is-active');
      }
    };
    if (this.slider.hasClass('slider--two-columns')) {
      $slide_width = jQuery('.storyportal__slider').width() / $itemNumber;
      options.minSlides = 1;
      options.maxSlides = $itemNumber;
      options.slideWidth = $slide_width;
      options.slideMargin = 30;
    }
    this.bxSlider = this.slider.find('.slider--images').bxSlider(options);
    this.bxSlider.find('li').each(function() {
      var $item, _src;
      $item = jQuery(this);
      _src = $item.find('img').attr('src');
      return $item.backstretch(_src);
    });
    return this.pagerItems.each((function(_this) {
      return function(index, element) {
        var $element;
        $element = jQuery(element);
        return $element.click(function(event) {
          if ($element.hasClass('is-active')) {
            return true;
          }
          _this.bxSlider.stopAuto();
          _this.bxSlider.goToSlide(index);
          return false;
        });
      };
    })(this));
  };

  DDSlider.prototype.matched_mobile = function() {
    return this.create_slider('1');
  };

  DDSlider.prototype.exit_mobile = function() {
    return this.create_slider('2');
  };

  return DDSlider;

})();

module.exports = DDSliderWatcher = (function() {
  function DDSliderWatcher() {
    jQuery('.slider').once('slider', function(index, element) {
      var slider;
      slider = new DDSlider(element);
      return slider.create_slider('2');
    });
  }

  return DDSliderWatcher;

})();
