var DDSlider, FooterNavigation, HeaderNavigation, HeaderSearch, ImagesLoaded, SocialSharePrivacy;

ImagesLoaded = require('imagesloaded');

ImagesLoaded.makeJQueryPlugin(jQuery);

window.Modernizr = require('./modernizr');

require("../../../../../bower_components/requestAnimationFrame/app/requestAnimationFrame.js");

require("../../../../../bower_components/jquery-debounce/jquery.debounce.js");

require("../../../../../bower_components/jquery.easing/js/jquery.easing.js");

require("../../../../../bower_components/scrollreveal/dist/scrollreveal.js");

require("../../../../../bower_components/jquery-transitionend/jquery-transitionend.js");

require("../../../../../bower_components/jquery-ui-slider/jquery-ui.min.js");

require("../../../../../bower_components/jquery-infield-label/lib/jquery.infieldlabel.js");

require("../../../../../bower_components/jStorage/jstorage.js");

require("../../../../../bower_components/jquery.uniform/jquery.uniform.js");

require("../../../../../bower_components/waypoints/lib/jquery.waypoints.js");

require("../../../../../bower_components/waypoints/lib/shortcuts/inview.js");

require("../../../../../bower_components/js-breakpoints/breakpoints.js");

require('./drupal');

HeaderNavigation = require('./components/header-navigation');

HeaderSearch = require('./components/header-search');

FooterNavigation = require('./components/footer-navigation');

DDSlider = require('./components/dd_slider');

require('./components/dd_calculator');

require('./components/dd_order');

require("../js/vendor/2clicksocialmedia/js/social_bookmarks.js");

SocialSharePrivacy = require('./components/social_share_privacy');

Drupal.viewPort = function() {
  var viewPortHeight, viewPortWidth;
  viewPortWidth = void 0;
  viewPortHeight = void 0;
  if (typeof window.innerWidth !== "undefined") {
    viewPortWidth = window.innerWidth;
    viewPortHeight = window.innerHeight;
  } else if (typeof document.documentElement !== "undefined" && typeof document.documentElement.clientWidth !== "undefined" && document.documentElement.clientWidth !== 0) {
    viewPortWidth = document.documentElement.clientWidth;
    viewPortHeight = document.documentElement.clientHeight;
  } else {
    viewPortWidth = document.getElementsByTagName("body")[0].clientWidth;
    viewPortHeight = document.getElementsByTagName("body")[0].clientHeight;
  }
  return [viewPortWidth, viewPortHeight];
};

Drupal.getScreenWidth = function() {
  var current_orientation, unit, wider;
  unit = "width";
  current_orientation = window.orientation;
  wider = window.screen.width > window.screen.height;
  if ((current_orientation === 180 && wider) || (current_orientation === 0 && wider) || (current_orientation === -90 && !wider) || (current_orientation === 90 && !wider)) {
    unit = "height";
  }
  return unit = window.screen[unit];
};

Drupal.getZoomFactor = function() {
  var _width;
  if (!Modernizr.touchevents) {
    return;
  }
  _width = Drupal.getScreenWidth();
  return window.innerWidth / _width;
};

Drupal.behaviors.viewport_conditions = {
  attach: function() {
    var styles;
    styles = require('./components/stylemanager');
    return jQuery('body').once('sections_height', function() {
      var $header, _width, update;
      _width = Drupal.getScreenWidth();
      $header = jQuery('.header');
      update = function() {
        var _b, _header_height, _height, _viewport;
        _viewport = Drupal.viewPort();
        _width = _viewport[0];
        _height = _viewport[1];
        if ($header[0] != null) {
          _b = $header[0].getBoundingClientRect();
          _header_height = _b.height;
        } else {
          _header_height = 0;
        }
        styles.register('js-viewport-width', ".js-viewport-width {width: " + _width + "px}");
        styles.register('js-viewport-height', ".js-viewport-height {height: " + _height + "px}");
        styles.register('js-viewport-height-front-stage', ".front .base--page-header .slider {\n  height: " + (_height - 200) + "px;\n}\n.front .base--wrapper {\n  padding-top: " + (_height - 200) + "px;\n}");
        return true;
      };
      jQuery(window).resize(update);
      return update();
    });
  }
};

Drupal.behaviors.header_navigation = {
  attach: function() {
    return new HeaderNavigation();
  }
};

Drupal.behaviors.header_search = {
  attach: function() {
    return new HeaderSearch();
  }
};

Drupal.behaviors.dd_search = {
  attach: function() {
    var $icon, $target, $trigger;
    $trigger = jQuery('#js--search--trigger');
    $icon = $trigger.find('.icon');
    $target = jQuery('#views-exposed-form-dd-search-search');
    $trigger.click(function(event) {
      event.preventDefault();
      $target.add('is-active');
      $icon.add('icon-search');
      return $icon.add('icon-close');
    });
  }
};

Drupal.behaviors.footer_navigation = {
  attach: function() {
    return new FooterNavigation();
  }
};

Drupal.behaviors.theme_dd_slider = {
  attach: function() {
    return new DDSlider();
  }
};

Drupal.behaviors.header_paralax = {
  attach: function() {
    if (Modernizr.touchevents) {
      return;
    }
    return jQuery('.front .base--page-header').once('header_paralax', function() {
      var _draw, _screen, _scroll, _y;
      _y = 0;
      _screen = Drupal.viewPort();
      _draw = (function(_this) {
        return function() {
          var _transform;
          _transform = "translateY(-" + (_y * 0.5) + "px)";
          _this.style.OTransform = _transform;
          _this.style.WebkitTransform = _transform;
          _this.style.MozTransform = _transform;
          _this.style.msTransform = _transform;
          return _this.style.transform = _transform;
        };
      })(this);
      _scroll = function(event) {
        _y = window.scrollY || window.pageYOffset;
        return window.requestAnimationFrame(_draw);
      };
      return jQuery(window).bind('scroll.header_paralax', _scroll).resize(function() {
        _screen = Drupal.viewPort();
        return _scroll();
      });
    });
  }
};

Drupal.behaviors.dd_scroll_separator = {
  attach: function() {
    return jQuery('.separator').once('separator', function() {
      var $element;
      $element = jQuery(this);
      return $element.on('click', function(event) {
        var _offset;
        _offset = $element.next('.entity-paragraphs-item').offset();
        return jQuery('html, body').stop().animate({
          scrollTop: _offset.top - 30
        }, 750, 'easeOutQuad');
      });
    });
  }
};

Drupal.behaviors.dd_theme_infield_labels = {
  attach: function() {
    var selector;
    selector = '.js-infield-labels .form-type-textfield label';
    selector += ', .js-infield-labels .form-type-textarea label';
    selector += ', .js-infield-labels .webform-component-textfield label';
    selector += ', .js-infield-labels .webform-component-textarea label';
    selector += ', .js-infield-labels .webform-component-email label';
    return selector += ', .js-infield-labels .webform-component-number label';
  }
};

Drupal.behaviors.theme_external = {
  attach: function() {
    var host;
    host = window.location.host;
    return jQuery('a:not(.link--external-blocked)').once('theme_external', function() {
      if (this.href.length < 2) {
        return;
      }
      if (this.href.indexOf(host) !== -1) {
        return;
      }
      if (this.href.indexOf('mailto') !== -1) {
        return;
      }
      this.target = '_blank';
      return this.className += " link--external";
    });
  }
};

Drupal.behaviors.theme_uniform = {
  attach: function() {
    return jQuery('.form-checkbox, .form-radio').uniform();
  }
};

Drupal.behaviors.dd_theme_tabs = {
  attach: function() {
    return jQuery('.js-tabs').once('js-tabs', function() {
      var $content, $header, $list, $wrapper;
      $wrapper = jQuery(this);
      $header = $wrapper.find('> .js-tabs--title a');
      $content = $header.next();
      $list = jQuery('<ul class="tabs--items" />');
      $header.each(function(index, element) {
        var $parent;
        $parent = jQuery(element).parent();
        $list.append(element);
        return $parent.remove();
      });
      $list.children().wrap('<li class="tabs--item"></li>');
      $list.prependTo($wrapper);
      return $wrapper.tabs();
    });
  }
};

Drupal.behaviors.dd_theme_accordion = {
  attach: function() {
    return jQuery('.js-accordion').once('js-accordion', function() {
      var $wrapper, options;
      $wrapper = jQuery(this);
      options = {
        header: '.js-accordion--title',
        heightStyle: 'content'
      };
      options.active = false;
      options.collapsible = true;
      return $wrapper.accordion(options);
    });
  }
};

Drupal.behaviors.dd_layout_collapsible = {
  attach: function() {
    var $wrapper;
    $wrapper = jQuery('.section-style__collapsible');
    $wrapper.each(function(index, element) {
      var $collapsible, $trigger;
      $collapsible = jQuery(this);
      $trigger = $collapsible.find('.section--content-title');
      $trigger.append('<i class="section-style__collapsible--icon fa fa-arrow-down"></i>');
      return $trigger.click(function() {
        return $collapsible.toggleClass('is-opened');
      });
    });
  }
};

Drupal.behaviors.dd_social_share_privacy = {
  attach: function() {
    return new SocialSharePrivacy();
  }
};

Drupal.behaviors.theme_dd_sitemap = {
  attach: function() {
    var $sitemap, $sitemap_link;
    $sitemap_link = jQuery('#js--footer-sitemap');
    $sitemap = jQuery('.footer--sitemap');
    return $sitemap_link.click(function(e) {
      e.preventDefault();
      $sitemap.toggleClass("is-active");
      $sitemap_link.toggleClass("is-active");
      if ($sitemap.hasClass("is-active")) {
        jQuery("html,body").animate({
          scrollTop: jQuery(document).height()
        }, 3000);
        false;
      }
      return false;
    });
  }
};

Drupal.behaviors.theme_dd_bricks = {
  attach: function() {
    return jQuery('.js--bricks').once('bricks', function() {
      var $brick, $bricks_icon, $bricks_links, $bricks_trigger;
      $brick = jQuery(this);
      $bricks_trigger = $brick.find('.js--bricks-trigger');
      $bricks_links = $brick.find('.js--bricks--links');
      $bricks_icon = $brick.find('.bricks--icon a');
      console.log($bricks_trigger);
      return $bricks_trigger.click(function(e) {
        console.log(this);
        e.preventDefault();
        $bricks_trigger.toggleClass("is-active");
        $bricks_icon.toggleClass("is-active");
        return $bricks_links.toggleClass("is-active");
      });
    });
  }
};

Drupal.behaviors.dd_imagemap = {
  attach: function() {
    var $imagemaps, $introbox, $introbox_button;
    $imagemaps = jQuery('.js--dd-imagemap');
    $introbox = jQuery('.js--dd-imagemap-intro');
    $introbox_button = jQuery('.js--dd-imagemap-intro-button');
    $introbox_button.click(function(event) {
      var $zoom_marker;
      event.preventDefault();
      $introbox.css({
        'opacity': 0
      });
      $zoom_marker = $imagemaps.find('.js--dd-imagemap--marker__zoom');
      $zoom_marker.each(function(index, element) {
        var marker;
        marker = jQuery(element);
        return marker.css({
          'display': 'table'
        });
      });
      return $introbox.css({
        'display': 'none'
      });
    });
    $imagemaps.each(function(index, element) {
      var $backbutton, acc, close, contents, hide_all_markers, i, image, imagemap, link_markers, markers, overlay, reset, reset_zooms, results, show_all_markers, zoom_markers;
      imagemap = jQuery(element);
      markers = imagemap.find('.js--dd-imagemap--marker');
      zoom_markers = imagemap.find('.js--dd-imagemap--marker__zoom');
      link_markers = imagemap.find('.js--dd-imagemap--marker__link');
      overlay = imagemap.find('.js--dd-imagemap--overlay');
      close = imagemap.find('.js--dd-imagemap--close');
      contents = imagemap.find('.js--dd-imagemap--content');
      image = jQuery('.dd-imagemap--image').children('img');
      $backbutton = jQuery('#dd_imagemap-backbutton');
      if (zoom_markers.length >= 1) {
        $introbox.css({
          "display": "block",
          "opacity": 1
        });
      }
      image.css({
        'transition': '0.45s transform ease-in-out'
      });
      reset_zooms = function() {
        zoom_markers.removeClass('is--zoomed');
        image.css({
          'transform': 'scale(1)'
        });
        $backbutton.css({
          'display': 'none'
        });
        return jQuery('#base--content').find('.dd-imagemap__modalbox-zoom').css({
          'opacity': 0,
          'transform': 'translate(-50%, -50%) scale(0.2)'
        });
      };
      hide_all_markers = function() {
        zoom_markers.css({
          'display': 'none'
        });
        markers.css({
          'display': 'none'
        });
        return link_markers.css({
          'display': 'none'
        });
      };
      show_all_markers = function() {
        zoom_markers.css({
          'display': 'table'
        });
        markers.css({
          'display': 'block'
        });
        return link_markers.css({
          'display': 'block'
        });
      };
      reset = function(event) {
        event.preventDefault();
        overlay.fadeOut();
        markers.removeClass('is--active');
        markers.find('.icon-close').removeClass('icon-close').addClass('icon-arrow-right');
        return contents.fadeOut();
      };
      markers.each(function(index, element) {
        var marker;
        marker = jQuery(element);
        return marker.click(function(event) {
          var content_id;
          event.preventDefault();
          if (marker.hasClass('is--active')) {
            return reset(event);
          } else {
            overlay.fadeIn();
            content_id = marker.attr('href');
            markers.removeClass('is--active');
            marker.addClass('is--active');
            marker.find('i').removeClass('icon-arrow-right').addClass('icon-close');
            contents.fadeOut();
            return jQuery(content_id).fadeIn();
          }
        });
      });
      zoom_markers.each(function(index, element) {
        var scaling_factor, transform_zoomed, translate_pos, zmarker, zmarker_left_percent, zmarker_top_percent;
        zmarker = jQuery(element);
        scaling_factor = 5;
        zmarker_top_percent = parseFloat(zmarker[0].style.top);
        zmarker_left_percent = parseFloat(zmarker[0].style.left);
        if (zmarker_left_percent < 5) {
          zmarker_left_percent = 5;
        }
        if (zmarker_left_percent > 95) {
          zmarker_left_percent = 95;
        }
        zmarker_left_percent = 50 - zmarker_left_percent;
        zmarker_top_percent = 50 - zmarker_top_percent;
        if (zmarker_left_percent <= -40) {
          zmarker_left_percent = -39.9;
        }
        if (zmarker_left_percent >= 40) {
          zmarker_left_percent = 39.99;
        }
        if (zmarker_top_percent <= -40) {
          zmarker_top_percent = -39.9;
        }
        if (zmarker_top_percent >= 40) {
          zmarker_top_percent = 39.99;
        }
        translate_pos = "translate(" + zmarker_left_percent + "%," + zmarker_top_percent + "%)";
        transform_zoomed = "scale(" + scaling_factor + ") " + translate_pos;
        zmarker.click(function(event) {
          var $modal, $target;
          event.preventDefault();
          $target = jQuery(event.target);
          $modal = jQuery('#dd__modalbox-zoom_id-' + $target.data("id"));
          if (zmarker.hasClass('is--zoomed')) {
            show_all_markers();
            zmarker.removeClass('is--zoomed');
            image.css({
              'transform': 'scale(1)'
            });
            $modal.css({
              'opacity': 0
            });
            $modal.css({
              'transform': 'translate(-50%, -50%) scale(0.2)'
            });
            $modal.css({
              'z-index': 0
            });
            return $backbutton.css({
              'display': 'none'
            });
          } else {
            zmarker.addClass('is--zoomed');
            hide_all_markers();
            image.css({
              'transform': transform_zoomed
            });
            $modal.css({
              'z-index': 0
            });
            $modal.css({
              'opacity': 1
            });
            $backbutton.css({
              'display': 'block'
            });
            if (zmarker.find('div.dd-imagemap--marker__title-right').length > 0) {
              return $modal.css({
                'transform': 'translate(-100%, -50%) scale(1)'
              });
            } else {
              return $modal.css({
                'transform': 'translate(0%, -50%) scale(1)'
              });
            }
          }
        });
        return $backbutton.click(function(event) {
          event.preventDefault();
          show_all_markers();
          return reset_zooms();
        });
      });
      overlay.click(function(event) {
        return reset();
      });
      jQuery('#base--content').find('.dd-imagemap__modalbox--close').click(function(event) {
        show_all_markers();
        return reset_zooms();
      });
      acc = document.getElementsByClassName('dd_list-item');
      i = void 0;
      i = 0;
      results = [];
      while (i < acc.length) {
        acc[i].addEventListener('click', function() {

          /* Toggle between adding and removing the "active" class,
          to highlight the button that controls the panel
           */
          var panel;
          this.classList.toggle('dd-active');

          /* Toggle between hiding and showing the active panel */
          panel = this.nextElementSibling;
          if (panel.style.display === 'block') {
            panel.style.display = 'none';
          } else {
            panel.style.display = 'block';
          }
        });
        results.push(i++);
      }
      return results;
    });
  }
};

Drupal.behaviors.dd_front_reveal = {
  attach: function() {
    return jQuery('body.front').once('front-reveal', function() {
      var _sr;
      _sr = new ScrollReveal({
        origin: 'bottom',
        viewFactor: 0.4,
        distance: '10%',
        mobile: false,
        rotate: {
          x: 0,
          y: 0,
          z: 0
        }
      });
      return _sr.reveal(jQuery('.section--module:visible').get());
    });
  }
};

Drupal.behaviors.dd_order_trigger = {
  attach: function() {
    var $form, $icon;
    $icon = jQuery('.icon-calendar');
    $form = jQuery('#edit-order-delivery-date-change-datepicker-popup-1, #edit-order-delivery-date-new-datepicker-popup-1');
    return $icon.click(function(event) {
      $form.trigger("select");
      return $form.focus();
    });
  }
};

Drupal.behaviors.dd_iframe = {
  attach: function() {
    return iFrameResize({
      log: false
    }, '#vlinkiframe');
  }
};
