var ref, ref1;

if (!(typeof Drupal !== "undefined" && Drupal !== null ? (ref = Drupal.ajax) != null ? (ref1 = ref.prototype) != null ? ref1.commands : void 0 : void 0 : void 0)) {
  return;
}

Drupal.ajax.prototype.commands.theme_redirect = function(ajax, response, status) {
  if (response["arguments"] == null) {
    return;
  }
  if (response["arguments"][0].constructor === String) {
    return window.location = response["arguments"][0];
  }
};
